<template>
  <div class="favorites">
    <div class="intro">
      <h2>Your favorites.</h2>
      <h5>
        <a href="https://twitter.com/valentinvieriu" target="_blank"
          >@art42net</a
        >
      </h5>
    </div>
    <div
      class="content"
      :style="{
        'margin-top': -canvasSize / 4 + 'px'
      }"
    >
      <RecycleScroller
        class="scroller"
        :items="artStream"
        :prerender="artStreamLength"
        :item-size="canvasSize"
        :pageMode="true"
        :buffer="3 * canvasSize"
        key-field="id"
        v-slot="{ item }"
      >
        <div class="wrapper" :style="{ height: canvasSize + 'px' }">
          <Card
            :config="item"
            :isFavorite="isFavorite(item.id)"
            @imageClicked="imageClicked"
            @addFavorite="addFavorite"
            @removeFavorite="removeFavorite"
          />
        </div>
      </RecycleScroller>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import innerHeight from 'ios-inner-height'
import Vue from 'vue'
import Card from '@/components/Card.vue'
export default {
  name: 'home',
  components: {
    Card
  },
  data() {
    return {
      canvasSize: 320,
      itemSize: 320
    }
  },
  computed: {
    artStream() {
      return Object.keys(this.favorites).map(id => {
        return this.favorites[id]
      })
    },
    favorites() {
      return this.$store.state.favorites
    },
    lockedAdd() {
      return this.$store.state.lockedAdd
    },
    artStreamLength() {
      return this.artStream.length
    }
  },
  beforeMount() {
    this.calculateLayout()
  },
  methods: {
    isFavorite(id) {
      return !!this.$store.state.favorites[id]
    },
    imageClicked(e) {
      this.$router.push({
        name: 'artwork',
        params: { artist: 'cubism', id: e.id }
      })
    },
    addFavorite(e) {
      this.$store.dispatch('addFavorite', e)
    },
    removeFavorite(e) {
      this.$store.dispatch('removeFavorite', e)
    },
    calculateLayout() {
      this.canvasSize = innerHeight()
      this.itemSize = this.canvasSize <= 1024 ? this.canvasSize : 1024
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ececec;
}
.intro {
  background-color: #fff;
  padding: 25px 10px 15px 10px;
  text-align: center;
  position: relative;
  z-index: 2;
}
.content {
  position: relative;
  z-index: 1;
}
</style>
