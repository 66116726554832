<template>
  <div class="tv" @click="toggleFullScreen">
    <div class="wrapper">
      <div
        class="content"
        v-for="item in tvStream"
        :key="item.id"
        :style="{ height: canvasSize + 'px', width: 100 / itemsCount + '%' }"
      >
        <Card :config="item" :hideActions="true" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import innerHeight from 'ios-inner-height'
import Vue from 'vue'
import Card from '@/components/Card.vue'
import { getRandomItem, gTagId, uuidv4 } from '../util.js'
let intervalRef
export default {
  name: 'tv',
  components: {
    Card
  },
  data() {
    return {
      canvasSize: 320,
      itemSize: 320,
      tvStream: []
    }
  },
  computed: {
    refreshInterval() {
      return Number(this.$route.query.refreshInterval) || 180
    },
    itemsCount() {
      return Number(this.$route.query.itemsCount) || 2
    },
    maxImages() {
      return this.$store.state.maxImages
    },
    currentArtist() {
      return this.$store.state.currentArtist
    }
  },
  beforeMount() {
    this.$store.dispatch('setNavigationVisibility', false)
    this.calculateLayout()
    document.addEventListener('fullscreenchange', event => {
      this.calculateLayout()
    })
    this.updateTvStream()
    intervalRef = setInterval(() => {
      this.updateTvStream()
    }, this.refreshInterval * 1000)
  },
  beforeDestroy() {
    clearInterval(intervalRef)
  },
  methods: {
    calculateLayout() {
      this.canvasSize = innerHeight()
      this.itemSize = this.canvasSize <= 1024 ? this.canvasSize : 1024
    },
    updateTvStream() {
      this.tvStream = [...Array(this.itemsCount)].map(() =>
        getRandomItem(this.maxImages, this.currentArtist)
      )
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
</style>
